<template>
  <div class="vehicle-list plan-list">
    <van-nav-bar left-arrow title="车辆档案" @click-left="$router.back()" />
    <van-search
      v-model="queryInfo.vehicleCode"
      class="search-bar"
      placeholder="请输入车牌号关键字"
      @clear="onRefresh"
      @search="onRefresh"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div class="search-query">
      <vehicle-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="trouble-total">共{{ total }}条结果</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="vehicle-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="item.id"
          :class="[
            'vehicle-list__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          :border="false"
          @click="gotoDetails(item)"
        >
          <div class="left">
            <div class="top">
              <span>{{ item.vehicleCode }}</span>
              <van-tag
                v-if="item.vehicleType === '1'"
                class="temp"
                color="#F13030"
                >临</van-tag
              >
              <van-tag class="not-temp" color="#E1EDFF" text-color="#3683F4">{{
                item.vehicleModelName
              }}</van-tag>
            </div>
            <div class="bottom">
              <div class="b-left">
                <van-icon
                  class-prefix="iconfont"
                  class="list-icon"
                  color="#aeb3c0"
                  name="bumen"
                />
                <span>{{ item.departmentName || "--" }}</span>
              </div>
              <div class="b-right">
                <van-icon
                  class-prefix="iconfont"
                  class="list-icon"
                  color="#aeb3c0"
                  name="yonghu1"
                />
                <span class="text-overflow name">{{
                  item.driverName || "--"
                }}</span>
                （<a
                  class="tel-click"
                  :href="`tel:${item.driverPhone}`"
                  @click="onAClick($event)"
                  >{{ item.driverPhone || "--" }}</a
                >）
              </div>
            </div>
          </div>
          <div class="right">
            <div
              class="status"
              :style="{ color: statusColor(item.bindCardStatus) }"
            >
              <van-icon
                :name="statusName(item.bindCardStatus)"
                class-prefix="iconfont"
                class="trouble-status-icon"
              />
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getPageVehicle } from "@/api/psm";
import VehicleQuery from "./components/VehicleQuery";
import "@/views/plans/styles/list.scss";
import { iconStatue } from "./utils/constant";

export default {
  name: "VehicleList",
  components: { VehicleQuery },
  data() {
    return {
      list: [],
      total: 0,
      queryInfo: {
        vehicleCode: "",
        vehicleModel: "",
        vehicleType: "",
        status: "2",
        orgCode: "",
        size: 10,
        page: 1
      },
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  computed: {
    statusName() {
      return function(status) {
        status = String(status);
        if (status === "null" || !status) return "";
        return iconStatue[status]["iconName"];
      };
    },
    statusColor() {
      return function(status) {
        status = String(status);
        if (status === "null" || !status) return "";
        return iconStatue[status]["iconColor"];
      };
    }
  },
  activated() {
    const { department, orgCode } = this.userInfo;
    this.queryInfo.publishScope = department;
    this.queryInfo.orgCode = orgCode;
    this.onRefresh();
  },
  methods: {
    onAClick(ev) {
      ev.stopPropagation();
      return false;
    },
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const { list, total } = await getPageVehicle(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails({ id }) {
      this.$router.push({
        name: "VehicleDetails",
        params: { id }
      });
    }
  }
};
</script>

<style lang="scss">
.vehicle-list {
  .search-bar {
    padding-bottom: 10px;
  }
  .tel-click {
    color: $--color-primary;
    // color: red;
  }
  &__wrapper {
    overflow-y: auto;
    height: calc(100vh - 58px - 70px - 80px);
  }
  &__rows {
    border-bottom: 8px solid #eceef2;
    .van-cell__value {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .left {
      .top {
        margin-bottom: 10px;
        span {
          margin-right: 4px;
        }
        .temp {
          padding: 0 2px;
        }
        .not-temp {
          padding: 0 6px;
          border: 1px solid #3683f4;
          border-radius: 2px;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        color: #8c8f97;
        .list-icon {
          margin-right: 6px;
        }
        .b-left {
          display: flex;
          align-items: center;
          margin-right: 20px;
        }
        .b-right {
          display: flex;
          align-items: center;
          .name {
            display: inline-block;
            max-width: 50px;
          }
        }
      }
    }
    .right {
      .status {
        width: 45px;
        height: 45px;
        i {
          font-size: 40px;
          line-height: 45px;
        }
      }
    }
  }
}
</style>
