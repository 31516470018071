<template>
  <div class="vehicle-list-query">
    <div
      v-for="(item, idx) in configs"
      :key="idx"
      class="vehicle-list-query__item"
      @click="onSearchClick(item)"
    >
      <span class="vehicle-list-query__item-title">{{ item.title }}</span>
      <van-icon
        name="zhankai"
        class-prefix="iconfont"
        class="vehicle-list-query__item-icon"
      />
    </div>
    <cascade-department
      v-model="department"
      :visible="departmentVisible"
      include-children
      @close="departmentVisible = false"
      @confirm="refreshQuery"
    />
    <select-popup
      :choose-value="popupValue"
      :show-filter="false"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { getSelectCarsVehicleModel, getSelectVehicleTypes } from "@/api/psm";
export default {
  name: "VehicleQuery",
  components: { SelectPopup },
  props: ["query"],
  data() {
    return {
      department: "",
      queryInfo: {
        troubleLevel: "",
        department: "",
        subDepartment: true,
        // 计划状态
        status: "",
        // 排查级别
        orgLevel: "",
        // 排查类型
        checkType: "",
        itemType: ""
      },
      configs: [
        {
          title: "车辆类型",
          type: "vehicleType",
          types: "vehicleTypes"
        },
        {
          title: "车型",
          type: "vehicleModel",
          types: "vehicleModels"
        },
        {
          title: "部门",
          type: "department"
        },
        {
          title: "定位绑卡状态",
          type: "bindCardStatus",
          types: "selectBindStatus"
        }
      ],
      departmentVisible: false,
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      vehicleTypes: [],
      vehicleModels: [],
      selectBindStatus: [
        {
          label: "已绑卡",
          value: "1"
        },
        {
          label: "未绑卡",
          value: "0"
        }
      ],
      selectValue: "",
      popupValue: "",
      popupType: ""
    };
  },
  created() {
    this.initTypes();
  },
  methods: {
    refreshQuery(selectValue, includeInfo) {
      this.queryInfo.department = this.department;
      this.queryInfo.subDepartment = includeInfo?.include;
      this.$emit("refresh", this.queryInfo);
    },
    async initTypes() {
      try {
        this.vehicleTypes = await getSelectVehicleTypes();
        this.vehicleModels = await getSelectCarsVehicleModel();
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onSearchClick({ title, type, types }) {
      if (type === "department") {
        this.departmentVisible = true;
      } else {
        this.popupValue = this.queryInfo[type] || "";
        this.popupType = type;
        this.popupData = this[types];
        this.popupTitle = title;
        this.popupVisible = true;
      }
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss">
.vehicle-list-query {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 14px;
  border-bottom: 1px solid #c7c9d0;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
}
</style>
