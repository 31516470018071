export const iconStatue = {
  "1": {
    iconName: "yibangka",
    iconColor: "#8EB433"
  },
  "0": {
    iconName: "weibangka",
    iconColor: "#C0C4CC"
  }
};
